import { modalIds } from '@/constants';
import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';

export default function InterestedSection(props: {
  color?: string;
  img?: string;
}) {
  return (
    <section
      className={`flex flex-col justify-center items-center gap-4 py-24 my-12 ${defaultStyles.padding}`}
      style={createStyleForBackgroundImage(props.img ?? '/interested-bg.png')}
    >
      <div className="text-[35px] xl:text-[45px] font-bold text-white text-center">
        Zainteresowany naszą ofertą?
      </div>
      <div
        className={`w-[78px] h-[3px] mb-8 ${props.color ? 'bg-[' + props.color + ']' : 'bg-[#F81E1E]'}`}
      />
      <div className="text-[18px] text-white text-center md:w-[70%]">
        Zaufaj naszemu wieloletniemu doświadczeniu w branży finansowej.
        Współpracujemy z renomowanymi inwestorami, oferując elastyczne i
        indywidualnie dopasowane rozwiązania finansowe. Skontaktuj się z nami,
        aby omówić, jak możemy wspierać rozwój Twojego biznesu.
      </div>
      <button
        onClick={() =>
          (
            document.getElementById(modalIds.basic) as HTMLDialogElement | null
          )?.showModal()
        }
        className={`cursor-pointer rounded-[8px] flex flex-row items-center justify-center gap-4 border-none outline-none ${props.color ? 'bg-[' + props.color + ']' : 'bg-[#F81E1E]'} w-[236px] h-[54px] mt-8`}
      >
        <div className="text-white font-bold text-[16px]">Wyślij zapytanie</div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4297 5.92993L20.4997 11.9999L14.4297 18.0699"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.5 12H20.33"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </section>
  );
}
