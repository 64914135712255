import { defaultStyles } from '@/utils/defaultStyles';
import Carousel from 'react-multi-carousel';
import { CustomLeftArrow, CustomRightArrow } from './CustomCarouselArrows';

function Slide(props: {
  text: string;
  avatar: string;
  name: string;
  position: string;
}) {
  return (
    <div className="mx-4 p-4 rounded-[8px] h-[356px] flex flex-col justify-between overflow-hidden bg-white">
      <div className="text-[24px] text-black">{props.text}</div>
      <div className="flex flex-row gap-2">
        <img
          alt="user-avatar"
          width={56}
          height={56}
          className="rounded-full"
          src={props.avatar}
        />
        <div className="flex flex-col gap-2">
          <div className="text-black text-[20px]">{props.name}</div>
          <div className="text-[#646A69] text-[20px]">{props.position}</div>
        </div>
      </div>
    </div>
  );
}

const slides = [
  {
    text: 'Dzięki szybkiemu finansowaniu z tej firmy mogłem zrealizować inwestycję w nowe maszyny. Polecam!',
    avatar: '/placeholder-avatar.png',
    name: 'Ewa Kowalska',
    position: 'Lorem ipsum',
  },
  {
    text: 'Uzyskałem kredyt pod zastaw biura, co pozwoliło mi otworzyć nową filię mojej firmy.',
    avatar: '/placeholder-avatar.png',
    name: 'Ewa Kowalska',
    position: 'Lorem ipsum',
  },
  {
    text: 'Proces był prosty, a decyzja szybka. To wsparcie pozwoliło mi rozwinąć działalność rolną.',
    avatar: '/placeholder-avatar.png',
    name: 'Ewa Kowalska',
    position: 'Lorem ipsum',
  },
  {
    text: 'Dzięki pożyczce mogłem dokończyć projekt deweloperski, który wcześniej stanął w miejscu. Jestem bardzo zadowolony.',
    avatar: '/placeholder-avatar.png',
    name: 'Ewa Kowalska',
    position: 'Lorem ipsum',
  },
  {
    text: 'Potrzebowałem kapitału na nową inwestycję. Dzięki tej firmie otrzymałem środki bez zbędnych formalności.',
    avatar: '/placeholder-avatar.png',
    name: 'Ewa Kowalska',
    position: 'Lorem ipsum',
  },
  {
    text: 'Elastyczne warunki kredytu pomogły mi utrzymać płynność finansową w trudnym okresie. Świetna współpraca!',
    avatar: '/placeholder-avatar.png',
    name: 'Ewa Kowalska',
    position: 'Lorem ipsum',
  },
  {
    text: 'Współpraca z tą firmą była kluczowa dla rozwoju mojego biznesu. Rekomenduję każdemu przedsiębiorcy!',
    avatar: '/placeholder-avatar.png',
    name: 'Ewa Kowalska',
    position: 'Lorem ipsum',
  },
];

export default function ClientReviewsSection(props: { color?: string }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1424 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1424, min: 764 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 764, min: 0 },
      items: 1,
    },
  };

  return (
    <section
      className={`flex flex-col gap-4 py-12 ${props.color ? 'bg-[' + props.color + ']' : 'bg-[#008837]'} ${defaultStyles.paddingBig}`}
      id="reviews"
    >
      <div className="text-[35px] xl:text-[45px] text-white">
        Co sądzą o nas{' '}
        <span className="text-white font-extrabold">Nasi klienci</span>
      </div>
      <div className="w-[78px] h-[3px] mb-8 bg-white" />
      <Carousel
        responsive={responsive}
        slidesToSlide={1}
        infinite
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        className="pb-12"
      >
        {slides.map((item, index) => (
          <Slide {...item} key={index} />
        ))}
      </Carousel>
    </section>
  );
}
