import { modalIds } from '@/constants';
import { defaultStyles } from '@/utils/defaultStyles';

function Item(props: { img: string; text: JSX.Element; circle: JSX.Element }) {
  return (
    <div className="flex flex-col items-center">
      <img height={256} src={props.img} alt="how-it-works-step" />
      {props.circle}
      <div className="text-center text-[30px] text-black">{props.text}</div>
    </div>
  );
}

export default function HowWorksSection(props: { color?: string }) {
  const items = [
    {
      img: '/work-1.png',
      circle: (
        <div
          className={`w-[105px] h-[105px] border-[3px] border-solid flex flex-col justify-center items-center ${props.color ? 'border-[' + props.color + '] bg-[' + props.color + '28]' : 'border-[#F81E1E] bg-[#F81E1E30]'} rounded-full`}
        >
          <div
            className={`text-[38px] font-extrabold ${props.color ? 'text-[' + props.color + ']' : 'text-[#F81E1E]'}`}
          >
            1
          </div>
        </div>
      ),
      text: (
        <>
          Wypełnij{' '}
          <span
            className={`font-bold ${props.color ? 'text-[' + props.color + ']' : 'text-[#F81E1E]'}`}
          >
            krótki formularz kontaktowy.
          </span>
        </>
      ),
    },
    {
      img: '/work-2.png',
      circle: (
        <div
          className={`w-[105px] h-[105px] border-[3px] border-solid flex flex-col justify-center items-center ${props.color ? 'border-[' + props.color + '] bg-[' + props.color + '28]' : 'border-[#F81E1E] bg-[#F81E1E30]'} rounded-full`}
        >
          <div
            className={`text-[38px] font-extrabold ${props.color ? 'text-[' + props.color + ']' : 'text-[#F81E1E]'}`}
          >
            2
          </div>
        </div>
      ),
      text: (
        <>
          W trakcie rozmowy{' '}
          <span
            className={`font-bold ${props.color ? 'text-[' + props.color + ']' : 'text-[#F81E1E]'}`}
          >
            oceniamy Twoją sytuację finansową
          </span>{' '}
          i pomysł na biznes.
        </>
      ),
    },
    {
      img: '/work-3.png',
      circle: (
        <div
          className={`w-[105px] h-[105px] border-[3px] border-solid flex flex-col justify-center items-center rounded-full ${props.color ? 'border-[' + props.color + '] bg-[' + props.color + '28]' : 'border-[#008837] bg-[#00883728]'}`}
        >
          <div
            className={`text-[38px] font-extrabold ${props.color ? 'text-[' + props.color + ']' : 'text-[#008837]'}`}
          >
            3
          </div>
        </div>
      ),
      text: (
        <>
          Otrzymasz szybką decyzję{' '}
          <span
            className={`font-bold ${props.color ? 'text-[' + props.color + ']' : 'text-[#008837]'}`}
          >
            o przyznaniu finansowania.
          </span>
        </>
      ),
    },
  ];

  return (
    <section
      id="how_it_works"
      className={`flex flex-col justify-center items-center gap-8 py-12 my-12 ${defaultStyles.padding}`}
    >
      <div className="text-[35px] xl:text-[45px] text-black">
        Prosty proces{' '}
        <span
          className={`font-extrabold ${props.color ? 'text-[' + props.color + ']' : 'text-[#F81E1E]'}`}
        >
          uzyskania kredytu
        </span>
      </div>
      <div
        className={`w-[78px] h-[3px] mb-8 ${props.color ? 'bg-[' + props.color + ']' : 'bg-[#F81E1E]'}`}
      />
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </div>
      <button
        onClick={() =>
          (
            document.getElementById(modalIds.basic) as HTMLDialogElement | null
          )?.showModal()
        }
        className={`cursor-pointer rounded-[8px] flex flex-row items-center justify-center gap-4 border-none outline-none ${props.color ? 'bg-[' + props.color + ']' : 'bg-[#F81E1E]'} w-[236px] h-[54px] mt-8`}
      >
        <div className="text-white font-bold text-[16px]">Wyślij zapytanie</div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4297 5.92993L20.4997 11.9999L14.4297 18.0699"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.5 12H20.33"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </section>
  );
}
