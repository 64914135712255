import { useState } from 'react';
import BurgerIcon from './BurgerIcon';
import { modalIds } from '@/constants';

function ContactButton(props: { class?: string; isButtonGreen?: boolean }) {
  return (
    <button
      onClick={() =>
        (
          document.getElementById(modalIds.basic) as HTMLDialogElement | null
        )?.showModal()
      }
      className={`cursor-pointer rounded-[8px] flex flex-row items-center justify-center gap-4 border-none outline-none  ${props.isButtonGreen ? 'bg-[#008837] drop-shadow-[0_10px_10px_#008837]' : 'bg-[#F81E1E] drop-shadow-[0_10px_10px_#F81E1E]'}  w-[236px] h-[54px] ${props.class}`}
    >
      <div className="text-white font-bold text-[16px]">Wyślij zapytanie</div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4297 5.92993L20.4997 11.9999L14.4297 18.0699"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5 12H20.33"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}

export const routes = [
  {
    title: 'Strona główna',
    route: '/',
  },
  {
    title: 'Oferta',
    id: 'offer',
  },
  {
    title: 'Dla kogo',
    id: 'for_who',
  },
  {
    title: 'Warunki umowy',
    id: 'terms',
  },
  {
    title: 'Dlaczego my?',
    id: 'why_us',
  },
  {
    title: 'Jak to działa?',
    id: 'how_it_works',
  },
];

function Navbar(props: { withoutHero?: boolean; isButtonGreen?: boolean }) {
  const [isBurgerToggled, setIsBurgerToggled] = useState(false);

  return (
    <div className={`bg-white`}>
      <div>
        <nav className={`flex flex-row items-center justify-between`}>
          <div className="flex flex-row gap-4 z-40 xl:bg-[#ECECEC] py-4 px-8">
            <img
              loading="lazy"
              src="/logo.png"
              alt="duoinvest logo"
              className="w-[254px] h-[76px]"
            />
          </div>
          <div className="xl:hidden flex flex-row justify-center items-center pr-8">
            <BurgerIcon
              isToggled={isBurgerToggled}
              onClick={() => setIsBurgerToggled(!isBurgerToggled)}
            />
          </div>
          <div className="hidden xl:flex flex-row gap-[18px] pr-8">
            {routes.map((item, index) => (
              <div
                key={index}
                className={`flex flex-col gap-1 cursor-pointer hover:border-black border border-solid border-t-0 border-r-0 border-l-0 border-transparent transition-all duration-300`}
                onClick={() => {
                  if (item.route) {
                    window.location.assign(item.route);
                  } else if (item.id) {
                    if (
                      window.location.pathname == '/rodo' ||
                      window.location.pathname == '/privacy' ||
                      window.location.pathname == '/terms'
                    ) {
                      window.location.assign('/');
                    }
                    document
                      .getElementById(item.id)!
                      .scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                <div className="self-end text-[10px] text-[#959595]">
                  0{index + 1}
                </div>
                <div className="text-[16px] text-black">{item.title}</div>
              </div>
            ))}
            <ContactButton class="ml-4" isButtonGreen={props.isButtonGreen} />
          </div>
        </nav>
        {isBurgerToggled && (
          <div className="w-full bg-white pb-8 pl-8">
            <div className="flex flex-col gap-4 items-start">
              {routes.map((item, index) => (
                <div
                  key={index}
                  className={`flex flex-col gap-1 cursor-pointer hover:border-black border border-solid border-t-0 border-r-0 border-l-0 border-transparent transition-all duration-300`}
                  onClick={() => {
                    if (item.route) {
                      window.location.assign(item.route);
                    } else if (item.id) {
                      if (
                        window.location.pathname == '/rodo' ||
                        window.location.pathname == '/privacy' ||
                        window.location.pathname == '/terms'
                      ) {
                        window.location.assign('/');
                      }
                      document
                        .getElementById(item.id)!
                        .scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                >
                  <div className="self-start text-[10px] text-[#959595]">
                    0{index + 1}
                  </div>
                  <div className="text-[16px] text-black">{item.title}</div>
                </div>
              ))}
              <ContactButton isButtonGreen={props.isButtonGreen} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
