import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';

function DistinctionItem(props: { svg: JSX.Element; text: JSX.Element }) {
  return (
    <div className="flex flex-col gap-4 bg-white rounded-[8px] p-4 border border-solid border-[#E8E8E8] drop-shadow-lg lg:h-[246px]">
      {props.svg}
      <div className="text-black text-[30px]">{props.text}</div>
    </div>
  );
}

export default function DistinctionSection(props: {
  img?: string;
  color?: string;
}) {
  const items = [
    {
      svg: (
        <div
          className={`w-[102px] h-[102px] rounded-full flex flex-col justify-center items-center  border border-solid ${props.color ? 'border-[' + props.color + '] bg-[' + props.color + '28]' : 'border-[#F81E1E] bg-[#F81E1E30]'}`}
        >
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.90602 7.41771C7.84812 8.18719 6.16602 10.6209 6.16602 12.804V26.0998C6.16602 28.2114 7.5618 30.9851 9.2618 32.2556L16.9565 37.9998C19.4797 39.8967 23.6313 39.8967 26.1544 37.9998L33.8492 32.2556C35.5492 30.9851 36.945 28.2114 36.945 26.0998V12.804C36.945 10.603 35.2629 8.16929 33.205 7.39982L24.2755 4.0535C22.7544 3.49877 20.3208 3.49877 18.8355 4.0535L9.90602 7.41771Z"
              stroke={props.color ?? '#F81E1E'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.2578 21.3042L19.1389 24.1852L26.8336 16.4905"
              stroke={props.color ?? '#F81E1E'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
      text: (
        <>
          <span
            className={`font-extrabold ${props.color ? 'text-[' + props.color + ']' : 'text-[#F81E1E]'}`}
          >
            Nie sprawdzamy BIK{' '}
          </span>
          i historii kredytowej.
        </>
      ),
    },
    {
      svg: (
        <div
          className={`w-[102px] h-[102px] rounded-full flex flex-col justify-center items-center border border-solid ${props.color ? 'border-[' + props.color + '] bg-[' + props.color + '28]' : 'border-[#008837] bg-[#00883728]'}`}
        >
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.0004 32.9236C18.0101 32.9236 14.7754 29.6889 14.7754 25.6986C14.7754 21.7084 18.0101 18.4736 22.0004 18.4736C25.9906 18.4736 29.2254 21.7084 29.2254 25.6986C29.2254 29.6889 25.9906 32.9236 22.0004 32.9236Z"
              stroke={props.color ?? '#008837'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.4521 23.4409V25.1207C22.4521 25.7529 22.127 26.349 21.5671 26.6741L20.1943 27.505"
              stroke={props.color ?? '#008837'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.2251 39.7874C36.4862 39.7874 37.7867 36.8794 38.166 33.3391L39.5207 18.8891C40.0084 14.4819 38.744 10.8875 31.0313 10.8875H12.9688C5.25615 10.8875 3.99177 14.4819 4.47946 18.8891L5.83415 33.3391C6.21346 36.8794 7.51396 39.7874 14.7751 39.7874H29.2251Z"
              stroke={props.color ?? '#008837'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.7754 10.8876V9.4426C14.7754 6.24553 14.7754 3.6626 20.5554 3.6626H23.4454C29.2254 3.6626 29.2254 6.24553 29.2254 9.4426V10.8876"
              stroke={props.color ?? '#008837'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M39.4304 19.9187C36.3056 22.1946 32.8376 23.7841 29.2432 24.6872"
              stroke={props.color ?? '#008837'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.05762 20.4065C8.07405 22.4656 11.3614 23.9287 14.7752 24.7596"
              stroke={props.color ?? '#008837'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
      text: (
        <>
          <span
            className={`font-extrabold ${props.color ? 'text-[' + props.color + ']' : 'text-[#008837]'}`}
          >
            10 lat doświadczenia{' '}
          </span>
          w pozyskiwaniu finansowania.
        </>
      ),
    },
    {
      svg: (
        <div
          className={`w-[102px] h-[102px] rounded-full flex flex-col justify-center items-center border border-solid ${props.color ? 'border-[' + props.color + '] bg-[' + props.color + '28]' : 'border-[#008837] bg-[#00883728]'}`}
        >
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.186 23.9328C35.186 29.496 32.007 31.8802 27.2385 31.8802H11.3616C10.5487 31.8802 9.77205 31.8081 9.04955 31.6455C8.59799 31.5732 8.16451 31.4468 7.76713 31.3023C5.05776 30.2908 3.41406 27.9426 3.41406 23.9328V14.6305C3.41406 9.06729 6.59305 6.68311 11.3616 6.68311H27.2385C31.2845 6.68311 34.1926 8.39903 34.9693 12.3186C35.0957 13.0411 35.186 13.7816 35.186 14.6305V23.9328Z"
              stroke={props.color ?? '#008837'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M40.6069 29.3515C40.6069 34.9148 37.428 37.299 32.6595 37.299H16.7825C15.4459 37.299 14.2357 37.1184 13.1881 36.7211C11.0386 35.9263 9.57557 34.2826 9.05176 31.6455C9.77426 31.8081 10.5509 31.8802 11.3638 31.8802H27.2407C32.0092 31.8802 35.1882 29.496 35.1882 23.9328V14.6305C35.1882 13.7816 35.116 13.023 34.9715 12.3186C38.4033 13.0411 40.6069 15.4614 40.6069 20.0493V29.3515Z"
              stroke={props.color ?? '#008837'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.2881 24.0588C16.6545 24.0588 14.5195 21.9238 14.5195 19.2903C14.5195 16.6567 16.6545 14.5217 19.2881 14.5217C21.9216 14.5217 24.0566 16.6567 24.0566 19.2903C24.0566 21.9238 21.9216 24.0588 19.2881 24.0588Z"
              stroke={props.color ?? '#008837'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.95898 15.3169V23.2644"
              stroke={props.color ?? '#008837'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.626 15.3174V23.2649"
              stroke={props.color ?? '#008837'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
      text: (
        <>
          <span
            className={`text-[#008837] font-extrabold ${props.color ? 'text-[' + props.color + ']' : 'text-[#008837]'}`}
          >
            50 milionów złotych{' '}
          </span>
          pozyskanego finansowania dla polskich firm.
        </>
      ),
    },
    {
      svg: (
        <div
          className={`w-[102px] h-[102px] rounded-full flex flex-col justify-center items-center border border-solid ${props.color ? 'border-[' + props.color + '] bg-[' + props.color + '28]' : 'border-[#F81E1E] bg-[#F81E1E30]'}`}
        >
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22 26.2407C19.5061 26.2407 17.4844 24.219 17.4844 21.7251C17.4844 19.2312 19.5061 17.2095 22 17.2095C24.4939 17.2095 26.5156 19.2312 26.5156 21.7251C26.5156 24.219 24.4939 26.2407 22 26.2407Z"
              stroke={props.color ?? '#F81E1E'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M33.7412 17.2095V26.2407"
              stroke={props.color ?? '#F81E1E'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.35684 39.7876C5.36658 39.7876 2.13184 36.5529 2.13184 32.5626C2.13184 28.5724 5.36658 25.3376 9.35684 25.3376C13.3471 25.3376 16.5818 28.5724 16.5818 32.5626C16.5818 36.5529 13.3471 39.7876 9.35684 39.7876Z"
              stroke={props.color ?? '#F81E1E'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.80762 30.3047V31.9845C9.80762 32.6167 9.48251 33.2127 8.92257 33.5378L7.5498 34.3688"
              stroke={props.color ?? '#F81E1E'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.9375 27.5052V16.3064C3.9375 9.98452 7.55 7.27515 12.9687 7.27515H31.0312C36.45 7.27515 40.0625 9.98452 40.0625 16.3064V27.1439C40.0625 33.4658 36.45 36.1751 31.0312 36.1751H15.6781"
              stroke={props.color ?? '#F81E1E'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
      text: (
        <>
          <span
            className={`font-extrabold ${props.color ? 'text-[' + props.color + ']' : 'text-[#F81E1E]'}`}
          >
            Długi okres kredytowania.
          </span>
        </>
      ),
    },
  ];

  return (
    <section
      id="why_us"
      className={`flex flex-col gap-4 py-12 my-12 ${defaultStyles.padding}`}
      style={createStyleForBackgroundImage(props.img ?? '/distinction-bg.png', {
        withDarkOverlay: false,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <div className="text-[35px] xl:text-[45px] text-black">
        Co nas{' '}
        <span
          className={`font-extrabold ${props.color ? 'text-[' + props.color + ']' : 'text-[#F81E1E]'}`}
        >
          Wyróżnia?
        </span>
      </div>
      <div
        className={`w-[78px] h-[3px] mb-8 ${props.color ? 'bg-[' + props.color + ']' : 'bg-[#F81E1E]'}`}
      />
      <div className="rounded-[16px] border border-solid border-[#E2E2E2] bg-white p-4 grid grid-cols-1 lg:grid-cols-2 gap-4 drop-shadow-md max-w-[907px]">
        {items.map((item, index) => (
          <DistinctionItem key={index} {...item} />
        ))}
      </div>
    </section>
  );
}
