import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';
import Navbar from './Navbar';
import { modalIds } from '@/constants';
import { ContactForm } from './ContactForm';

export default function HeroSection(props: {
  img?: string;
  title?: string;
  desc?: string;
  isNavGreen?: boolean;
  contactFormRed?: boolean;
}) {
  return (
    <section
      className={`flex flex-col gap-32 min-h-[800px]`}
      style={createStyleForBackgroundImage(props.img ?? '/homepage-hero.png', {
        withDarkOverlay: false,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Navbar isButtonGreen={props.isNavGreen} />
      <div
        className={`flex flex-col xl:flex-row gap-8 pb-12 ${defaultStyles.paddingBig}`}
      >
        <div className="flex flex-col gap-8">
          <div className="text-white font-bold text-[30px] md:text-[45px] xl:text-[55px] xl:w-[60%]">
            {props.title ?? 'Prywatne finansowanie dla Twojego biznesu'}
          </div>
          <div className="text-white text-[16px] xl:w-[60%] leading-6">
            {props.desc ??
              'Kredyty dla firm i pożyczki pod zastaw nieruchomości'}
          </div>
          <button
            onClick={() =>
              (
                document.getElementById(
                  modalIds.basic,
                ) as HTMLDialogElement | null
              )?.showModal()
            }
            className={`cursor-pointer rounded-[8px] flex flex-row items-center justify-center bg-transparent gap-4 border-white border-solid outline-none w-[236px] h-[54px]`}
          >
            <div className="text-white font-bold text-[16px]">
              Wyślij zapytanie
            </div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.4297 5.92993L20.4997 11.9999L14.4297 18.0699"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.5 12H20.33"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <ContactForm contactFormRed={props.contactFormRed} />
      </div>
    </section>
  );
}
